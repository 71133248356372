<script>
	import { getContext, createEventDispatcher } from "svelte";

	import redirect from "../../util/redirect.js";
	import uuid from "../../util/uuid.js";

	export let className = "primary";
	export let size = null;
	export let extraClasses = "";
	export let outline = false;
	export let square = false;
	export let tooltip = null;
	export let tooltipDirection = "top";
	export let indicator = null;
	export let path = null;
	export let disabled = false;
	export let id = uuid();
	export let closeMenu = false;

	const dispatch = createEventDispatcher();

	const closeMenusUnlessFullSideMargins = getContext(
		"closeMenusUnlessFullSideMargins"
	);

	$: colorClass = {
		default: "",
		primary: "btn-primary",
		secondary: "btn-secondary",
		accent: "btn-accent",
		neutral: "btn-neutral",
		info: "btn-info",
		success: "btn-success",
		warning: "btn-warning",
		error: "btn-error",
	}[className];

	$: sizeClass =
		{
			small: "btn-sm",
			xs: "btn-xs",
		}[size] || "";

	$: outlineClass = outline ? "btn-outline" : "";
	$: squareClass = square ? "btn-square" : "";
	$: tooltipDirectionClass = {
		top: "tooltip-top",
		bottom: "tooltip-bottom",
		left: "tooltip-left",
		right: "tooltip-right",
	}[tooltipDirection];
	$: tooltipClass = tooltip ? `tooltip ${tooltipDirectionClass}` : "";

	function handleClick() {
		if (closeMenu) closeMenusUnlessFullSideMargins();
		if (path) return redirect(path);
		dispatch("click");
	}
</script>

<div class="{tooltipClass} w-full indicator" data-tip={tooltip}>
	{#if indicator?.value}
		<div
			class="indicator-item badge badge-sm sm:badge-md {indicator.class ||
				'badge-secondary'}"
		>
			{indicator.value}
		</div>
	{/if}
	<button
		class="btn {colorClass} {sizeClass} {extraClasses} {outlineClass} {squareClass}"
		{id}
		data-tip={tooltip}
		{disabled}
		on:click={handleClick}
	>
		<slot />
	</button>
</div>

<style>
	.tooltip:before {
		z-index: 50;
	}
</style>
