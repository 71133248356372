<script>
	import Fetcher from "../../../../api/Fetcher";

	import paramsStore from "../../../../stores/paramsStore";
	import forumsStore from "../../../../stores/data/forumsStore";

	import UserBadge from "../../../shared/UserBadge.svelte";
	import Icon from "../../../icons/large/Icon.svelte";

	export let user;

	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];

	function handleRemoveClick() {
		Fetcher.removeForumAdmin({ user, forumSlug });
	}
</script>

<div class="flex gap-1">
	<div class="flex-grow"></div>
	<UserBadge {user} size="lg" />
	{#if forum?.userPermissions?.removeAdmin && !user.super}
		<!-- svelte-ignore a11y-click-events-have-key-events -->
		<div
			class="text-error cursor-pointer tooltip z-50"
			data-tip="Remove"
			on:click={handleRemoveClick}
		>
			<Icon name="x" />
		</div>
	{/if}
	<div class="flex-grow"></div>
</div>
