<script>
	import hoverStore from "../stores/hoverStore.js";

	import LeftMenu from "./sideMenus/left/LeftMenu.svelte";
	import RightMenu from "./sideMenus/right/RightMenu.svelte";
	import Main from "./main/Main.svelte";
	import PostBox from "./postBox/PostBox.svelte";
	import TopBar from "./topBar/TopBar.svelte";
	import Toasts from "./tailwind/Toasts.svelte";
	import FormModalMain from "./shared/modal/FormModalMain.svelte";

	export let postBox;

	let leftMenu, rightMenu;

	export function closeMenus() {
		leftMenu.closeMenu();
		rightMenu.closeMenu();
	}

	function handleHover(location) {
		hoverStore.set(location);
	}
</script>

<TopBar on:mouseenter={() => handleHover("topBar")} />
<LeftMenu bind:this={leftMenu} on:mouseenter={() => handleHover("leftMenu")} />
<RightMenu
	bind:this={rightMenu}
	on:mouseenter={() => handleHover("rightMenu")}
/>
<Main on:mouseenter={() => handleHover("main")} />
<PostBox bind:this={postBox} on:mouseenter={() => handleHover("postbox")} />
<Toasts />
<FormModalMain />
