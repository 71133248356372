<script>
	import { getContext, createEventDispatcher } from "svelte";

	import currentUserStore from "../../stores/data/currentUserStore.js";
	import paramsStore from "../../stores/paramsStore.js";

	import redirect from "../../util/redirect.js";
	import { redirectToTopic } from "../../util/redirectTo.js";

	export let path = null;
	export let topic = null;
	export let topicSlug = null;
	export let postNumber = null;
	export let colorName = null;
	export let tooltip = null;
	export let clickable = false;

	const dispatch = createEventDispatcher();

	const closeMenusUnlessFullSideMargins = getContext(
		"closeMenusUnlessFullSideMargins"
	);

	$: currentUser = $currentUserStore;
	$: currentTopicSlug = $paramsStore.topicSlug;

	$: colorClass =
		{
			primary: "link-primary",
			secondary: "link-secondary",
			accent: "link-accent",
			neutral: "link-neutral",
			success: "link-success",
			info: "link-info",
			warning: "link-warning",
			error: "link-error",
		}[colorName] || "";

	$: tooltipClass = tooltip ? "tooltip" : "";

	function handleClick() {
		dispatch("click");
		if (topic || topicSlug) {
			const pageSize = currentUser?.settings?.pageSize || 50;
			closeMenusUnlessFullSideMargins();
			redirectToTopic({
				topic,
				topicSlug,
				pageSize,
				postNumber,
				currentTopicSlug,
			});
		} else if (path) {
			closeMenusUnlessFullSideMargins();
			redirect(path);
		}
	}
</script>

{#if path || topic || topicSlug || clickable}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div
		class="inline-flex link {colorClass} {tooltipClass} max-w-full"
		data-tip={tooltip}
		on:click={handleClick}
	>
		<slot />
	</div>
{:else}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div
		class="inline-flex {colorClass} {tooltipClass} max-w-full"
		data-tip={tooltip}
		on:click
	>
		<slot />
	</div>
{/if}
