<script>
	import { getContext } from "svelte";

	import currentUserStore from "../../../../stores/data/currentUserStore.js";
	import bookmarksStore from "../../../../stores/data/bookmarksStore.js";

	import { redirectToTopic } from "../../../../util/redirectTo.js";

	import Fetcher from "../../../../api/Fetcher";
	import Icon from "../../../icons/small/Icon.svelte";

	export let bookmark;

	const closeMenusUnlessFullSideMargins = getContext(
		"closeMenusUnlessFullSideMargins"
	);

	$: currentUser = $currentUserStore;
	$: pageSize = currentUser?.settings?.pageSize || 50;

	function goToBookmark() {
		closeMenusUnlessFullSideMargins();
		redirectToTopic({
			pageSize,
			postNumber: bookmark.tag,
			topicSlug: bookmark.topicSlug,
			sectionSlug: bookmark.sectionSlug,
			forumSlug: bookmark.forumSlug,
		});
	}

	function unbookmark() {
		Fetcher.unbookmark({ bookmark }).then(handleResponse);
	}

	function handleResponse({ id }) {
		bookmarksStore.removeBookmark(id);
	}
</script>

<div class="bg-base-300 py-2 px-4 rounded-lg flex gap-2 cursor-pointer">
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="grow max-w-full text-sm" on:click={goToBookmark}>
		{bookmark.topicTitle}: #{bookmark.tag}
	</div>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div
		class="my-auto text-error cursor-pointer"
		on:click|stopPropagation={unbookmark}
	>
		<Icon name="bin" />
	</div>
</div>
