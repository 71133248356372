import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import redirect from "../util/redirect.js";
import permissionsTab1 from "../components/shared/permissionsTabs/forumPermissionsTab1.js";
import permissionsTab2 from "../components/shared/permissionsTabs/forumPermissionsTab2.js";

import domainsStore from "../stores/data/domainsStore.js";
import forumsStore from "../stores/data/forumsStore.js";

class NewForumForm extends Form {
	static title = "New Forum";
	static code = "New-forum";
	static submitValue = "Save";

	static initialPages() {
		return [
			{
				tab: "Basic",
				fields: [
					{
						type: "input",
						inputType: "text",
						value: "",
						label: "Title",
						id: "new-forum-title",
						placeholder: "Title",
						required: true,
					},
					{
						type: "input",
						inputType: "text",
						value: "",
						label: "Description",
						id: "new-forum-description",
						placeholder: "Description",
						maxLength: 128,
					},
					{
						type: "input",
						inputType: "urlType",
						value: "fromTitle",
						label: "URL",
						id: "new-forum-url-type",
						description:
							"A URL based off the title could cause another user to confirm the existence of a forum with this title, even if they don't have access to it.",
					},
				],
			},
			permissionsTab1({ level: "forum" }),
			permissionsTab2({ level: "forum" }),
		];
	}

	static async submit({ pages }) {
		const basicFields = pages[0].fields;
		const title = basicFields[0].value;
		const description = basicFields[1].value;
		const urlType = basicFields[2].value;

		const permissionsField1 = pages[1].fields[0];
		const permissionsField2 = pages[2].fields[0];
		const domainPermissions = permissionsField1.levels[0].value;
		const sectionGroupsPermissions = permissionsField1.levels[1].value;
		const sectionsPermissions = permissionsField1.levels[2].value;
		const topicsPermissions = permissionsField2.levels[0].value;

		const forumPermissions = {
			sectionGroups: sectionGroupsPermissions,
			sections: sectionsPermissions,
			topics: topicsPermissions,
		};

		return Fetcher.createDomain({
			title,
			description,
			urlType,
			domainPermissions,
			forumPermissions,
		});
	}

	static handleSuccess({ domain, forum }) {
		domainsStore.updateDomain(domain);
		forumsStore.updateForum(forum);
		redirect(`/${domain.slug}/forum`);
	}
}

export default NewForumForm;
