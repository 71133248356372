import { writable } from "svelte/store";
import API from "../../api/API";
import DateTime from "../../util/DateTime";

const { subscribe, set, update } = writable({});

let previousRequest;

function reset() {
	set({});
	previousRequest = null;
}

function fetchDomains() {
	const currentRequest = DateTime.now();
	API.get("domains", { previousRequest }).then(updateDomains);
	previousRequest = currentRequest;
}

function fetchDomain(slug) {
	API.get(`domains/${slug}`).then(updateDomain);
}

function updateDomains(newDomains) {
	update((domains) => {
		for (const newDomain of newDomains) {
			const slug = newDomain.slug;
			domains[slug] = {
				...domains[slug],
				...newDomain,
			};
		}
		return domains;
	});
}

function updateDomain(newDomain) {
	update((domains) => {
		const slug = newDomain.slug;
		domains[slug] = {
			...domains[slug],
			...newDomain,
		};
		return domains;
	});
}

export default {
	subscribe,
	reset,
	fetchDomains,
	fetchDomain,
	updateDomain,
};
