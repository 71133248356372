import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import permissionsTab1 from "../components/shared/permissionsTabs/sectionGroupPermissionsTab1.js";
import permissionsTab2 from "../components/shared/permissionsTabs/sectionGroupPermissionsTab2.js";

import sectionGroupsStore from "../stores/data/sectionGroupsStore.js";
import forumsStore from "../stores/data/forumsStore.js";

class NewSectionGroupForm extends Form {
	static title = "New Section Group";
	static code = "new-section-group";
	static submitValue = "Save";
	title = "New Section Group";
	code = "new-section-group";

	static initialPages({ forumSlug, domain, forum }) {
		return [
			{
				tab: "Basic",
				fields: [
					{
						type: "input",
						inputType: "text",
						value: "",
						label: "Title",
						id: "new-section-group-title",
						placeholder: "Title",
						required: true,
					},
				],
			},
			permissionsTab1({
				forumPermissions: {
					...(domain?.permissions || {}),
					...(forum?.permissions || {}),
				},
			}),
			permissionsTab2({
				forumPermissions: {
					...(domain?.permissions || {}),
					...(forum?.permissions || {}),
				},
			}),
			{ forumSlug },
		];
	}

	static async submit({ pages }) {
		const title = pages[0].fields[0].value;
		const permissionsLevels1 = pages[1].fields[0].levels;
		const permissionsLevels2 = pages[2].fields[0].levels;
		const sectionGroupPermissions = permissionsLevels1[0].value;
		const sectionsPermissions = permissionsLevels1[1].value;
		const topicsPermissions = permissionsLevels2[0].value;
		const permissions = {
			...sectionGroupPermissions,
			sections: sectionsPermissions,
			topics: topicsPermissions,
		};
		const { forumSlug } = pages[3];
		return Fetcher.createSectionGroup({
			forumSlug,
			title,
			permissions,
		});
	}

	static handleSuccess(json) {
		forumsStore.updateForum(json);
	}
}

export default NewSectionGroupForm;
