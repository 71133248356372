<script>
	import { getContext } from "svelte";

	import draftsStore from "../../../../stores/data/draftsStore.js";
	import layoutStore from "../../../../stores/layoutStore.js";

	import Fetcher from "../../../../api/Fetcher";
	import Icon from "../../../icons/small/Icon.svelte";

	export let draft;
	$: draftId = draft.id;

	const draftSelected = getContext("draftSelected");
	const draftDeleted = getContext("draftDeleted");
	const closeMenusUnlessFullSideMargins = getContext(
		"closeMenusUnlessFullSideMargins"
	);

	function selectDraft() {
		closeMenusUnlessFullSideMargins();
		draftSelected(draft.id);
	}

	function deleteDraft() {
		Fetcher.deleteDraft({ draftId }).then(handleDraftDeleted);
	}

	function handleDraftDeleted() {
		draftDeleted(draftId);
		draftsStore.fetchDrafts();
	}
</script>

<div class="bg-base-300 py-2 px-4 rounded-lg flex gap-2 cursor-pointer">
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div
		class="grow overflow-hidden"
		style="text-overflow: ellipsis; white-space: nowrap;"
		on:click={selectDraft}
	>
		{draft.text}
	</div>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="my-auto text-error" on:click={deleteDraft}>
		<Icon name="bin" />
	</div>
</div>
