<script>
	import { setContext } from "svelte";
	import { writable } from "svelte/store";

	import paramsStore from "../../../stores/paramsStore.js";
	import forumsStore from "../../../stores/data/forumsStore.js";
	import sectionsStore from "../../../stores/data/sectionsStore.js";
	import topicsStore from "../../../stores/data/topicsStore.js";
	import layoutStore from "../../../stores/layoutStore.js";
	import pageNameStore from "../../../stores/pageNameStore.js";
	import hoverStore from "../../../stores/hoverStore.js";

	import currentUserStore from "../../../stores/data/currentUserStore.js";
	import notificationsStore from "../../../stores/data/notificationsStore.js";
	import draftsStore from "../../../stores/data/draftsStore.js";
	import bookmarksStore from "../../../stores/data/bookmarksStore.js";

	import Icon from "../../icons/large/Icon.svelte";
	import MenuButton from "./MenuButton.svelte";

	export let side;
	export let items = [];
	export let getItems = null;

	const visibleStore = writable(false);

	setContext("visibleStore", visibleStore);
	setContext("side", side);

	let hovered = null;
	let displayed = null;
	let selected = null;
	let currentUsername = null;

	$: currentUser = $currentUserStore;
	$: forumSlug = $paramsStore.domainSlug;
	$: forum = $forumsStore[forumSlug];
	$: sectionSlug = $paramsStore.sectionSlug;
	$: section = $sectionsStore[sectionSlug];
	$: topicSlug = $paramsStore.topicSlug;
	$: topic = $topicsStore[topicSlug];
	$: notifications = $notificationsStore;
	$: drafts = $draftsStore;
	$: bookmarks = $bookmarksStore;

	$: handlePageNameChange($pageNameStore);

	$: setItems({
		getItems,
		currentUser,
		forum,
		section,
		topic,
		notifications,
		drafts,
		bookmarks,
	});

	$: handleDisplayedChange(displayed);

	$: handleHoverStoreChange($hoverStore);

	function handleDisplayedChange(displayed) {
		visibleStore.set(displayed != null);
	}

	export function closeMenu() {
		setTimeout(() => {
			selected = null;
			hovered = null;
		}, 300);
	}

	function setItems({
		getItems,
		currentUser,
		forum,
		section,
		topic,
		notifications,
		drafts,
	}) {
		if (!getItems || !currentUser) {
			items = [];
			return;
		}
		items = getItems({
			currentUser,
			forum,
			section,
			topic,
			notifications,
			drafts,
			bookmarks,
		});
	}

	$: handleHoveredChange(hovered);

	function handleHoveredChange(hovered) {
		if (typeof hovered === "number") {
			hoverStarted(hovered);
		} else if (hovered === null) {
			displayed = null;
		}
	}

	function hoverStarted(i) {
		setTimeout(() => {
			if (hovered === i) {
				displayed = i;
			}
		}, 250);
	}

	function handlePageNameChange() {
		setTimeout(() => {
			if (side === "right") {
				selected = null;
				hovered = null;
			}
		}, 250);
	}

	$: handleCurrentUserChange($currentUserStore);

	function handleCurrentUserChange(currentUser) {
		if (currentUser?.username === currentUsername) return;
		hovered = null;
		selected = null;
		currentUsername = currentUser?.username;
	}

	$: style = getStyle(
		side,
		$layoutStore.sidebarWidth,
		$layoutStore.menuOrientation
	);

	function getStyle(side, sidebarWidth, menuOrientation) {
		if (menuOrientation === "vertical") {
			return `${side}: 0px; width: ${sidebarWidth}px; height: 100vh;`;
		}
		side = { left: "top", right: "bottom" }[side];
		return `${side}: 0px; height: ${sidebarWidth}px; width: 100vw;`;
	}

	function handleHoverStoreChange(location) {
		if (location === "main") {
			hovered = null;
		}
	}
</script>

<div
	class="fixed z-50"
	on:mouseenter={() => {
		hovered = "menu";
	}}
>
	<!-- <div class="h-screen bg-accent p-2 fixed {side}-0 w-{barWidth}"> -->
	<div class="bg-primary-content p-2 fixed z-50" {style} on:mouseenter>
		<div class="menu-icons flex flex-row md:flex-col">
			{#each items as item, i}
				<MenuButton
					selected={selected === i}
					indicator={item[2]}
					on:click={() => {
						if (selected === i) {
							selected = null;
							hovered = null;
						} else {
							selected = i;
						}
					}}
					on:mouseenter={() => (hovered = i)}
					on:mouseleave={() => (hovered = "menu")}
				>
					<Icon name={item[0]} />
				</MenuButton>
			{/each}
		</div>
	</div>

	{#if $layoutStore.fullSideMargins || displayed != null || selected != null}
		<!-- svelte-ignore a11y-click-events-have-key-events -->
		<div
			class="bg-base-100 h-screen menu-display fixed overflow-auto"
			style="{side}: {$layoutStore.menuOrientation === 'vertical'
				? $layoutStore.sidebarWidth
				: 0}px; width:{$layoutStore.sideDisplayWidth}px; top: {$layoutStore.sideDisplayTopMargin}px;"
		>
			<div class="relative">
				{#each items as item, i (item[3] || item[0])}
					{#if displayed === i || (displayed === null && selected === i)}
						<svelte:component this={item[1]} />
					{/if}
				{/each}
			</div>
		</div>
	{/if}
</div>
