import channel from "./channel.js";

import forumsStore from "../stores/data/forumsStore.js";

const messageFunctions = {
	refetchForumData,
};

function domainsChannel(currentUser) {
	return channel({ channel: channelName(currentUser) }, messageFunctions);
}

function channelName(currentUser) {
	const authority = currentUser?.authority;
	if (authority == null || authority < 0) return "DomainsGuestChannel";
	if (authority < 10) return "DomainsMemberChannel";
	return "DomainsAdminChannel";
}

function refetchForumData({ slug }) {
	forumsStore.fetchForumData(slug);
}

export default domainsChannel;
