import { getColumns, values } from "./permissionsFunctions";

export default function sectionPermissionsTabs({
	sectionGroupPermissions = {},
	sectionPermissions,
} = {}) {
	const columns = getColumns(sectionGroupPermissions);
	return JSON.parse(
		JSON.stringify({
			tab: "Permissions",
			fields: [
				{
					type: "permissions",
					levels: [
						{
							heading: "Section",
							primary: true,
							columns,
							rows: ["view", "createTopic"],
							tooltips: ["Who can view the section", "Who can create topics"],
							...values({
								codes: ["view", "createTopic"],
								parent: sectionGroupPermissions.sections,
								existing: sectionPermissions,
							}),
						},
						{
							type: "permissions",
							heading: "Topics",
							columns,
							rows: [
								"viewMin",
								"viewDefault",
								"viewMax",
								"postMin",
								"postDefault",
								"postMax",
							],
							tooltips: [
								"The least restrictive a view permission can be",
								"Default view permission for topics",
								"The most restrictive a view permission can be",
								"The least restrictive a post permission can be",
								"Default post permission",
								"The most restrictive a post permission can be",
							],
							...values({
								codes: ["view", "post"],
								parent: sectionGroupPermissions?.topics,
								existing: sectionPermissions?.topics,
								meta: true,
							}),
						},
					],
				},
			],
		})
	);
}
