<script>
	import pluralize from "pluralize";

	import Fetcher from "../../../../api/Fetcher";
	import fetchCurrentUser from "../../../../api/fetchCurrentUser.js";
	import TopicBadge from "../../../main/forum/TopicBadge.svelte";
	import Icon from "../../../icons/small/Icon.svelte";

	export let notification;

	function deleteNotification() {
		Fetcher.deleteNotification({ notification }).then(fetchCurrentUser);
	}
</script>

<div class="bg-base-300 py-2 px-4 rounded-lg flex gap-2">
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="grow max-w-full">
		{#if notification.category === "replies"}
			<div class="max-w-full">
				<TopicBadge
					topic={notification.object}
					postNumber={notification.tag}
					on:click={deleteNotification}
				/>
			</div>
			has {pluralize("new reply", notification.number, true)}.
		{:else if notification.category === "added"}
			You have been added to
			<div class="max-w-full">
				<TopicBadge
					topic={notification.object}
					postNumber={notification.tag}
					on:click={deleteNotification}
				/>
			</div>
		{/if}
	</div>
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div class="my-auto text-error cursor-pointer" on:click={deleteNotification}>
		<Icon name="bin" />
	</div>
</div>
