import page from "page";
import scrollStore from "../stores/scrollStore";

export default function redirect(url) {
	const splitUrl = url.split("#");
	url = splitUrl[0];
	const id = splitUrl[1];
	if (id) {
		scrollStore.setId(id);
	} else {
		window.scrollTo(0, 0);
		scrollStore.setLocation("top");
	}
	page(url[0] === "/" ? url : `/${url}`);
}
