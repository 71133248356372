import { getColumns, values } from "./permissionsFunctions";

export default function sectionGroupPermissionsTab2({
	forumPermissions = {},
	sectionGroupPermissions,
} = {}) {
	const columns = getColumns(forumPermissions);
	return JSON.parse(
		JSON.stringify({
			tab: "Permissions 2",
			fields: [
				{
					type: "permissions",
					levels: [
						{
							type: "permissions",
							heading: "Topics",
							columns,
							rows: [
								"viewMin",
								"viewDefault",
								"viewMax",
								"postMin",
								"postDefault",
								"postMax",
							],
							tooltips: [
								"The least restrictive a view permission can be",
								"Default view permission for topics",
								"The most restrictive a view permission can be",
								"The least restrictive a post permission can be",
								"Default post permission",
								"The most restrictive a post permission can be",
							],
							...values({
								codes: ["view", "post"],
								parent: forumPermissions?.topics,
								existing: sectionGroupPermissions?.topics,
								meta: true,
							}),
						},
					],
				},
			],
		})
	);
}
