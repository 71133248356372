import Form from "./Form.js";
import Fetcher from "../api/Fetcher.js";
import permissionsTab1 from "../components/shared/permissionsTabs/sectionGroupPermissionsTab1.js";
import permissionsTab2 from "../components/shared/permissionsTabs/sectionGroupPermissionsTab2.js";

class EditSectionGroupForm extends Form {
	static title = "Edit Section Group";
	static code = "edit-section-group";
	static submitValue = "Save";

	static initialPages({
		forum,
		sectionGroup,
		domainPermissions,
		forumPermissions,
		sectionGroupPermissions,
	}) {
		return [
			permissionsTab1({
				forumPermissions: { ...domainPermissions, ...forumPermissions },
				sectionGroupPermissions: sectionGroupPermissions,
			}),
			permissionsTab2({
				forumPermissions: { ...domainPermissions, ...forumPermissions },
				sectionGroupPermissions: sectionGroupPermissions,
			}),
			{ forum, sectionGroup },
		];
	}

	static async submit({ pages }) {
		const permissionsField1 = pages[0].fields[0];
		const permissionsField2 = pages[1].fields[0];
		const sectionGroupPermissions = permissionsField1.levels[0].value;
		const sectionsPermissions = permissionsField1.levels[1].value;
		const topicsPermissions = permissionsField2.levels[0].value;

		const permissions = {
			...sectionGroupPermissions,
			sections: sectionsPermissions,
			topics: topicsPermissions,
		};

		const { forum, sectionGroup } = pages[2];

		return Fetcher.updateSectionGroup({
			forum,
			sectionGroup,
			permissions,
		});
	}
}

export default EditSectionGroupForm;
