<script>
	import { tick } from "svelte";

	import API from "../../../api/API";
	import redirect from "../../../util/redirect";

	import paramsStore from "../../../stores/paramsStore.js";
	import itemsCountStore from "../../../stores/itemsCountStore.js";
	import domainsStore from "../../../stores/data/domainsStore.js";
	import forumsStore from "../../../stores/data/forumsStore.js";
	import sectionsStore from "../../../stores/data/sectionsStore.js";
	import topicsStore from "../../../stores/data/topicsStore.js";

	export let activeRoute;

	let success = false;
	let body = {};

	$: params = activeRoute.params;

	$: domainSlug = params.slug || params.forumSlug;
	$: sectionSlug = params.sectionSlug;
	$: topicSlug = params.topicSlug;

	$: checkAccess(params);

	async function checkAccess(params) {
		body = {};
		success = false;
		await tick();
		if (
			domainSlug &&
			!Object.keys($domainsStore).includes(domainSlug) &&
			!Object.keys($forumsStore).includes(domainSlug)
		) {
			body.domain_slug = domainSlug;
		}
		if (
			sectionSlug &&
			sectionSlug !== "all" &&
			!Object.keys($sectionsStore).includes(sectionSlug)
		)
			body.section_slug = sectionSlug;
		if (topicSlug && !Object.keys($topicsStore).includes(topicSlug)) {
			body.topic_slug = topicSlug;
		}
		if (Object.keys(body).length > 0) {
			API.post("unlock", body).then((json) => handleResponse(params, json));
		} else {
			accessCheckPassed(params);
		}
	}

	function handleResponse(params, json) {
		const result = checkResponse(json);
		if (result === "success") {
			accessCheckPassed(params);
		} else {
			redirect(result);
		}
	}

	function checkResponse({ domain, section, topic }) {
		if (body.domain_slug) {
			if (!domain) return "/";
			domainsStore.updateDomain({ slug: domainSlug, ...domain });
		}
		if (body.section_slug) {
			if (!section) return `/${domainSlug}/forum`;
			sectionsStore.updateSection({ slug: sectionSlug, ...section });
		}
		if (body.topic_slug) {
			if (!topic) return `/${domainSlug}/forum/${sectionSlug}/p/1`;
			topicsStore.updateTopic({ slug: topicSlug, ...topic });
		}
		return "success";
	}

	function accessCheckPassed(params) {
		if (params.pageNumber) {
			params.pageNumber = parseInt(params.pageNumber) || params.pageNumber;
		}
		paramsStore.setParams(params);
		if (!params.pageNumber) {
			itemsCountStore.set(0);
		}
		success = true;
	}
</script>

{#if success}
	<slot />
{/if}
