<script>
	import { getContext, createEventDispatcher } from "svelte";

	import redirect from "../../util/redirect.js";

	export let colorName = "neutral";
	export let path = null;
	export let clickable = !!path;
	export let tooltip = null;
	export let extraClasses = "";
	export let backgroundColor = null;
	export let textColor = null;
	export let size = null;

	const dispatch = createEventDispatcher();

	const closeMenusUnlessFullSideMargins = getContext(
		"closeMenusUnlessFullSideMargins"
	);

	$: colorClass =
		{
			default: "",
			primary: "badge-primary",
			secondary: "badge-secondary",
			accent: "badge-accent",
			neutral: "badge-neutral",
			info: "badge-info",
			success: "badge-success",
			warning: "badge-warning",
			error: "badge-error",
		}[colorName] || "";

	$: sizeClass = {
		null: "",
		lg: "badge-lg",
		md: "badge-md",
		sm: "badge-sm",
		xs: "badge-xs",
	}[size];

	$: tooltipClass = tooltip ? "tooltip" : "";

	$: style =
		(backgroundColor
			? `background-color: ${backgroundColor}; border-color: ${backgroundColor};`
			: "") + (textColor ? `color: ${textColor}` : "");

	function handleClick() {
		if (path) {
			closeMenusUnlessFullSideMargins();
			redirect(path);
			return;
		}
		dispatch("click");
	}
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
	class="badge {colorClass} {sizeClass} {tooltipClass} {extraClasses} z-50 max-w-full px-3"
	class:cursor-pointer={clickable}
	data-tip={tooltip}
	on:click={handleClick}
	{style}
>
	<div class="truncate">
		<slot />
	</div>
</div>

<style>
	.tooltip:before {
		z-index: 50;
	}
</style>
