<script>
	import { getContext, createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	import Field from "./Field.svelte";
	import ErrorAlert from "../shared/alerts/ErrorAlert.svelte";
	import Tabs from "../shared/Tabs.svelte";
	import TabPage from "../shared/TabPage.svelte";

	export let fields = null;
	export let pages = [{ fields }];
	export let errors = {};
	export let submit = async () => ({});
	export let handleSuccess = async () => ({});
	export let submitValue = "Submit";
	export let submitId = "submit-button";
	export let cancelValue = "Cancel";
	export let cancelId = "cancel-button";
	export let modal = null;

	let tabs,
		activeTab,
		minHeight,
		heights = [];
	let loading = false;

	const closeMenusUnlessFullSideMargins = getContext(
		"closeMenusUnlessFullSideMargins"
	);

	$: if (pages.length > 0 && pages[0].tab) {
		tabs = pages.map((page) => page.tab);
		activeTab ||= tabs[0];
	} else {
		tabs = null;
		activeTab = null;
	}

	function handleEvent(event, field, page) {
		const eventType = event.detail.type;
		if (["input", "change"].includes(eventType)) {
			clearFormError();
			clearFieldError(field, page);
		}
		if (field.events && field.events[eventType]) {
			field.events[eventType]();
		}
	}

	function handleCancel() {
		errors = {};
		activeTab = null;
		modal && modal.close();
		dispatch("cancel");
	}

	function clearFormError() {
		if (errors.error) {
			delete errors.error;
			errors = errors;
		}
	}

	function addFieldError(error, field, page) {
		if (page?.tab == null) {
			errors[field.id] = error;
		} else {
			if (errors[page.tab] == null) errors[page.tab] = {};
			errors[page.tab][field.id] = error;
		}
	}

	function clearFieldError(field, page) {
		if (!page || page.tab == null) {
			const errorText = errors[field.id];
			if (errorText != null) {
				for (const id of Object.keys(errors)) {
					if (errors[id] === errorText) {
						delete errors[id];
					}
				}
				errors = errors;
			}
		} else {
			if (errors[page.tab] != null && errors[page.tab][field.id] != null) {
				delete errors[page.tab][field.id];
				errors[page.tab] = errors[page.tab];
				if (Object.keys(errors[page.tab]).length === 0) {
					delete errors[page.tab];
					errors = errors;
				}
			}
		}
	}

	function goToErrorTab() {
		if (tabs) {
			for (const tab of tabs) {
				if (errors[tab]) {
					activeTab = tab;
					break;
				}
			}
		}
	}

	async function handleSubmit() {
		if (loading) return;
		for (const page of pages) {
			for (const field of page.fields || []) {
				if (field.required && [undefined, null, ""].includes(field.value)) {
					addFieldError("Please enter a value.", field, page);
				}
			}
		}
		if (fields) {
			for (const field of fields) {
				if (field.required && [undefined, null, ""].includes(field.value)) {
					addFieldError("Please enter a value.", field);
				}
			}
		}
		if (anyErrors(errors)) return;
		loading = true;
		submit({ fields, pages }).then(handleResponse);
	}

	async function handleResponse(response) {
		loading = false;
		if (!response) return;
		if (response.errors) {
			errors = response.errors;
			goToErrorTab();
		} else {
			if (modal && modal.close) modal.close();
			handleSuccess(response);
			closeMenusUnlessFullSideMargins();
		}
		return response;
	}

	// const handleTabPageMount = (event) => {
	//   minHeight = Math.min(minHeight, event.detail.height);
	// };

	function anyErrors(errors) {
		for (const value of Object.values(errors)) {
			if (typeof value === "object") {
				if (anyErrors(value)) return true;
			} else {
				if (value != null) return true;
			}
		}
		return false;
	}

	function cleanupErrors(errors) {
		Object.entries(errors).forEach(([key, value]) => {
			if (value == null) {
				delete errors[key];
			} else if (typeof value === "object") {
				cleanupErrors(value);
			}
		});
	}

	$: minHeight = Math.max(...heights);

	$: cleanupErrors(errors);

	function onKeyDown(e) {
		if (!modal?.isOpen()) return;
		if (e.key === "Escape") {
			handleCancel();
		} else if (e.key === "Enter") {
			handleSubmit();
		}
	}
</script>

<svelte:window on:keydown={onKeyDown} />

<Tabs {tabs} bind:activeTab {errors} />

<form on:submit|preventDefault={() => {}}>
	{#if fields?.length > 0}
		{#each fields as field, i}
			<Field
				bind:field={fields[i]}
				bind:errors
				on:event={(event) => handleEvent(event, field)}
			/>
		{/each}
	{:else}
		{#each pages as page, i}
			<TabPage
				tab={page.tab}
				{activeTab}
				bind:minHeight
				bind:clientHeight={heights[i]}
			>
				{#each page.fields || [] as field}
					<Field
						bind:field
						tab={page.tab}
						bind:errors
						on:event={(event) => handleEvent(event, field, page)}
					/>
				{/each}
			</TabPage>
		{/each}
	{/if}

	{#if errors.error}
		<ErrorAlert text={errors.error} />
	{/if}

	<div class:modal-action={!!modal}>
		<slot name="buttons">
			<button id={submitId} class="btn btn-primary" on:click={handleSubmit}>
				{#if loading}
					<span class="loading loading-spinner"></span>
				{:else}
					{submitValue}
				{/if}
			</button>
			<button id={cancelId} class="btn" on:click={handleCancel}>
				{cancelValue}
			</button>
		</slot>
	</div>
</form>
